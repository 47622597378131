import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Donar from './pages/Donar';
import Main from './pages/Main';
import Rutas from './pages/Rutas';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Main/>} />
        <Route exact path="/rutas" element={<Rutas/>} />
        <Route exact path="/donar" element={<Donar/>} />
        
      </Routes>
    </Router>
  );
}

export default App;
