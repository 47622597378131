import Footer from "../components/general/Footer"
import NavBar from "../components/general/NavBar"
import HowItWork from "../components/main/HowItWork"
import '../styles/Main.css'
import logo from '../assets/logo.png'
const Main = () => {

    return (
        <>
            <NavBar />
            <div className="main-banner-image w-100  d-flex justify-content-center align-items-center">
                <div className='text-center'>
                    <img className="logo" src={logo} alt="logo" />
                    <h1 className="text-white text-bold">Rutas de los camiones urbanos en Uruapan, Michoacán</h1>
                    <br/>
                    <a className="btn btn-danger border-0 red-bg inner" href= "#cf">Como funciona</a>
                </div>
            </div>
            <span id="cf"></span>
            <HowItWork/>
            <Footer/>
        </>
    )
}

export default Main