import { Card, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import "../../styles/HowItWork.css"
import ej1 from '../../assets/ej1.png'
import ej2 from '../../assets/ej2.png'
import ej3 from '../../assets/ej3.png'
const HowItWork = () => {

    return (
        <>
            <Container >
                <Card className="my-5 inner shadow text-center">
                    <Card.Header>¿Cómo funciona?</Card.Header>
                    <Card.Body>
                        <Card.Title>Conoce las rutas con un clic</Card.Title>
                        
                            
                            1. Ve a nuestro apartado /rutas<br/>
                            <img className="my-2 img-fluid" src = {ej1} alt = "..."/>
                            <hr className="divider"/>
                            2. Selecciona una ruta para ver su recorrido<br/>
                            <img className="my-2 img-fluid" src = {ej2} alt = "..."/>
                            <hr className="divider"/>
                            3. Con el botón "ver en maps" podrás ver el mapa en la aplicación nativa de tu dispositivo, además puedes Desplegar el menú para ocultar las rutas de ida o de vuelta<br/>
                            <img className="my-2 img-fluid" src = {ej3} alt = "..."/>
                            <hr className="divider"/>
                        <Link  className="btn btn-danger border-0 red-bg inner" to = "/rutas">Ver las rutas</Link>
                        
                    </Card.Body>
                </Card>

            </Container>
        </>
    )
}

export default HowItWork