import IGLogo from '../../assets/instagram-logo.png'
import TWLogo from '../../assets/twitter-logo.png'
import GHLogo from '../../assets/github-logo.png'

const Footer = () => {

    return (
        <>
            <footer className="text-center text-white green-bg py-3">
                <div className="card-header">Contactanos</div>
                <div className="card-body ">
                    <a href="https://twitter.com/sm__alfredo" rel="noreferrer" target="_blank" className="card-text text-white">
                        <img  alt='...' src={TWLogo} className="m-2" />
                        @sm__alfredo
                    </a><br />
                    <a href="https://github.com/llFREEDll" rel="noreferrer" target="_blank" className="card-text text-white">
                        <img alt='...' src={GHLogo}  className="m-2" />
                        @llFREEDll
                    </a><br />
                    <a href="https://www.instagram.com/sm__alfredo/" rel="noreferrer" target="_blank" className="card-text text-white">
                        <img alt='...' src={IGLogo}  className="m-2" />
                        @sm__alfredo
                    </a>
                    <br />
                    <br />
                    <p>Este sitio web es independiente y no forma parte de ninguna organización, grupo, empresa o sindicato relacionado con los urbanos de Uruapan.</p>
                </div>
            </footer>
        </>
    )

}

export default Footer