import { useEffect, useState } from "react"
import { Card, Container, Form, Image, Spinner } from "react-bootstrap"
import publicidad from '../assets/anuncio.png'
import Footer from "../components/general/Footer"
import NavBar from "../components/general/NavBar"
const Rutas = () => {
    const [selectedItem, setSelectedItem] = useState("https://www.google.com/maps/d/u/0/embed?mid=12RObIFelFQ1NILD1EtR0vE1xVg4pTb8X&ehbc=2E312F")
    const HandleChange = (e) => {
        setSelectedItem(e.target.value)

    }


    const [data, setData] = useState(undefined)

    const GetData = async () => {

        const data = await fetch('https://rutas-uruapan-back.netlify.app/getRoutes')
            .then(response => response.json())
            .catch(undefined);
        setData(data.docs);
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        GetData();
    }, [])
    return (
        <>
            <NavBar />
            <Container>
                <Card className="my-5 shadow">
                    {data !== undefined ?

                        <Card.Body>
                            <Card.Title>Selecciona la ruta deseada</Card.Title>

                            <Form.Group className="mb-3">
                                <Form.Label>Ruta</Form.Label>
                                <Form.Select onChange={HandleChange}>
                                    {data.map((item, idx) => (
                                        <option value={item.map} key={idx}>{item.name + ": " + item.desc}</option>
                                    ))}

                                </Form.Select>
                                <a className="btn btn-danger border-0 red-bg inner mt-2 w-100" rel="noreferrer" target="_blank" href={selectedItem.replace("embed", "viewer")}>Ver en maps</a>
                            </Form.Group>
                            { }
                            <iframe title="ruta" className="mt-4 w-100" height="500vh" src={selectedItem}></iframe>
                        </Card.Body>
                        :
                        <div className="text-center my-5 py-5">
                            <Spinner animation="border" variant="danger" />
                        </div>
                    }
                </Card>
                <Card className="my-5 shadow text-center">
                    <Card.Body>
                        <Card.Title>Publicidad</Card.Title>

                        <Card.Text> Crea tu diseño de sublimación con nosotros, tazas, playeras y mucho más con tu diseño,
                            <br />
                            Aprovecha nuestras <b>ofertas</b> por apertura
                            <br /><br /><br />
                            <b>Cotizaciones: </b>
                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+524525041875/?text=Hola%2C%20quiero%20una%20taza">
                                 4525041875
                            </a>
                        </Card.Text>
                        <Image fluid src={publicidad} />
                    </Card.Body>

                </Card>
            </Container>
            <Footer />
        </>
    )
}

export default Rutas