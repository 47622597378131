import { useState } from "react"
import { Card, Container } from "react-bootstrap";
import Footer from "../components/general/Footer";
import NavBar from "../components/general/NavBar";
import '../styles/Donar.css'
const Donar = () => {

    useState(() => {
        window.scrollTo(0,0)

    }, [])
    return (
        <>
            <NavBar />
            <div className="w-100 donar-bg">
                <Container style={{ paddingTop: "20vh" }} className="text-center d-flex justify-content-center align-items-center">

                    <Card className="w-50 expandir">
                        <Card.Body>
                            <hr className="dropdown-divider" />
                            <Card.Title>Comprame un café</Card.Title>
                            <span className={"red-bg position-absolute top-0 start-50 translate-middle p-2  border border-light rounded-circle"}>
                                <span className="visually-hidden">New alerts</span>
                            </span>
                            <hr className="dropdown-divider" />
                            <Card.Text className="h-50 my-5">
                                ¡Si el sitio web te ha sido de utilidad puedes apoyarme comprándome un café!
                            </Card.Text>
                            <div className="mb-3">
                                <form action="https://www.paypal.com/donate" method="post" target="_top">
                                    <input type="hidden" name="hosted_button_id" value="V3XJ23R2H9YQA" />
                                    <input type="image" src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donar con el botón PayPal" />
                                    <img alt="" border="0" src="https://www.paypal.com/es_MX/i/scr/pixel.gif" width="1" height="1" />
                                </form>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
            <Footer />
        </>
    )
}
export default Donar