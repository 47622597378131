import { Container, Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import logo from "../../assets/logo.png"
const NavBar = () => {

    return (
        <>
            <Navbar variant="dark" className="green-bg text-uppercase" expand="md">
                <Container className="container-fluid">
                    <Link to="/" className="navbar-brand">
                        <img
                            alt="logo"
                            src={logo}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />{' '}
                        Rutas Uruapan
                    </Link>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Link to="/" className="nav-link">Inicio</Link>
                            <Link to="/rutas" className="nav-link">Rutas</Link>
                            <Link to="/donar" className="nav-link">Donar</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavBar